import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import Main from './app/Main';
// import Deals from './components/Deals/Deals';
// import bxInit from './components/services/bxInit';
// import updateDeals from './components/services/getDeals';
// import getFields from './components/services/getFields';
// import getCompanyList from './components/services/getCompanyList';
// import getCompany from './components/services/getCompany';
// import getDealFields from './components/services/getDealFields';

// import moment from 'moment';


const App = () => {

  // componentDidMount() {
  //   moment.locale('ru');
  //   bxInit();
  //   // BX24.callUnbind('ONCRMDEALUPDATE', 'https://ab3f703ea3f5bde66f90698dbf6d3e87.m.pipedream.net', 'auth_type=0','');
  //   // BX24.callBind('ONCRMDEALUPDATE','', 'auth_type=1', 'event_type=offline');
  //   this.updateData();
  //   // this.getUserFieldsDeal();
  //   // this.getUserFields();
  // }

  // componentWillUnmount() {}


    return (
    <Main/>
    )
}

export default App;
