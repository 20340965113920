const getSortStageKey = (stage_id) => {
    switch (stage_id) {
        case '10210275':
            return {key: 1, stageName: "Оплатили счёт"};
        
        case '13591469':
            return {key: 2, stageName: "Инвойс к оплате"};
        
        case '13591463':
            return {key: 3, stageName:"Оплата инвойса"};
        
        case '12804945': 
            return {key: 4, stageName: "Пр-во велкро"};
        
        case '13591471': 
            return {key: 5, stageName: "Про-во сетки"};
        
        case '13591472':
            return {key: 6, stageName: "Про-во 3D Спейсер"};
        
        case '13591473':
            return {key: 7, stageName: "Про-во петельное полотно"};

        case '13591474':
            return {key: 8, stageName: "Про-во Южная Корея"};
        
        case '13591464':
            return {key: 9, stageName: "Внутренняя доставка"};

        case '13591467':
            return {key: 10, stageName: "Склад консолидации"};

        case '13591470':
            return {key: 11, stageName: "Таможня"};
        
        case '13591466':
            return {key: 12, stageName: "В пути Авиа"};

        case '12804948':
            return {key: 13, stageName: "В пути Авто/ЖД"};

        case '12907605':
            return {key: 14, stageName: "Склад МСЖ"};

        case '13591458':
            return {key: 15, stageName: "Отгружено"};

        default: 
            break;
       
    };
}

export default getSortStageKey;