//@ts-check
/* eslint no-use-before-define: 0 */

import React, { Component } from "react";
import "./Deals";
import "../Deals/deals.css";
import "semantic-ui-css/semantic.min.css";
import moment from "moment";
import { Table, Icon, Label, Accordion, Header } from "semantic-ui-react";
import { blocks } from "./stageBlocks";
import { Progress } from "semantic-ui-react";

export default class Deals extends Component {
  
  state = {
    activeIndex: 0,
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const lastDigitToWord = (digit) => {
      const lastFigure = parseInt(
        digit.toString().substr(digit.toString().length - 1, 1)
      );
      if (digit >= 11 && digit < 15) {
        return "дней";
      } else {
        if (lastFigure == 1) return "день";
        if (lastFigure > 1 && lastFigure < 5) return "дня";
        if (lastFigure == 0 || lastFigure >= 5) return "дней";
      }
    };

    const {
      id,
      color,
      stage,
      companyName,
      name,
      deadline,
      start,
      totalDays,
      inviocePayDays,
      domesticShiping,
      warehouse,
      daysPass,
      daysLeft,
      beginDate,
      payStageTime,
      PayFact,
      currentStageTotal,

      invoiceStartPayDate,
      invoicePayFactDays,
      invoicePayPlanDate,
      invoicePayFactDate,

      productionDays,
      productStartDate,
      productionPlanDate,
      productionFactDate,
      productionFactDays,

      intDeliveryStartDate,
      intDeliveryPlanDate,
      intDeliveryFactDays,
      intDeliveryFactDate,

      warehouseStartDate,
      warehouseFactDays,
      warehousePlan,

      customStartDate,
      customFactDays,
      customFactDate,
      customPlan,
      customClearance,

      carShipment,
      carShipStartDate,
      carShipFactDate,
      carShipFactDays,
      carShipPlanDate,

      airShipDate,

      mskFactDate,
      // mskFactDays,

      unloadMsk,
      stageProgress,
      stageTotal,
    } = this.props;
    const { activeIndex } = this.state;

    let a = moment(beginDate);
    let b = moment(mskFactDate);
    const TotalDaysDiff = b.diff(a, "days").toString();

    let mark = null;

    // if (Number(mskFactDays) <= 0) {
    //   mark = "good";
    // } else if (mskFactDays !== "-") {
    //   mark = "good";
    // } else {
    //   mark = "bad";
    // }

    // const blocksArr = blocks(productionDaysProgress);

    const invoicePayDiffDays = Number(invoicePayFactDays) - Number(inviocePayDays);
    const productionDiffDays = Number(productionFactDays) - Number(productionDays);
    const domesticShipDiffDays = Number(intDeliveryFactDays) - Number(domesticShiping);
    const warehouseDiffDays = Number(warehouseFactDays) - Number(warehouse);
    const customDiffDays = Number(customFactDays) - Number(customClearance);
    const carShipDiffDays = Number(carShipFactDays) - Number(carShipment);
    const totalDaysPlan = Number(inviocePayDays) + Number(productionDays) + Number(domesticShiping) + Number(warehouse) + Number(customClearance) + Number(carShipment);
    const mskFactDays = Number(invoicePayFactDays) + Number(productionFactDays) + Number(intDeliveryFactDays) + Number(warehouseFactDays) + Number(customFactDays) + Number(carShipFactDays)
    const totalDiffDays = mskFactDays - totalDaysPlan;
    const deadLinePlan = moment(start).add(totalDaysPlan, 'days').format('DD.MM.YYYY');

    return (
      <div
        style={{
          background: "#3e3f41",
          margin: 5,
          paddingRight: 5,
          paddingLeft: 5,
          paddingBottom: 5,
          borderRadius: 3,
        }}
      >
        <Accordion>
          <Accordion.Title
            active={activeIndex === id}
            index={id}
            onClick={this.handleClick}
          >
            <Table inverted>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="5" style={{ cursor: "pointer" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: 25,
                      }}
                    >
                      <Label color={color} size="small" ribbon>
                        {stage}
                      </Label>
                      <div style={{ width: "100%" }}>
                        {stageTotal && (
                          <Progress
                            inverted
                            progress="ratio"
                            value={
                              stageProgress > stageTotal
                                ? stageTotal
                                : stageProgress
                            }
                            total={stageTotal}
                            error={stageProgress > stageTotal}
                            color={stageProgress > stageTotal ? null : color}
                            active
                          />
                        )}
                      </div>

                      {/* {blocksArr !== null ? blocksArr.map((i) => <div key={i} style={{background: color, width: 5, paddingLeft: 5}}/>) : null} */}
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width={5} style={{ cursor: "pointer" }}>
                    <Icon size="large" name="dropdown" />
                    {name}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    width={5}
                    style={{ cursor: "pointer" }}
                  >
                    {companyName}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{ cursor: "pointer" }}
                  >
                    <Header.Subheader>Крайний срок</Header.Subheader>
                    {deadline}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{ cursor: "pointer" }}
                  >
                    <Header.Subheader>
                      {totalDays} {lastDigitToWord(totalDays)}
                    </Header.Subheader>
                    {beginDate} - {deadline}
                  </Table.HeaderCell>
                  {/* className={mskFactDays > 0 || mskFactDays == '-' ? 'bad' : 'good'} */}
                  {unloadMsk !== "Invalid date" ? (
                    <Table.HeaderCell textAlign="center" colSpan="7">
                      Отгружен <br />
                      {unloadMsk}
                    </Table.HeaderCell>
                  ) : (
                    <>
                      {daysLeft >= 0 ? (
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ cursor: "pointer" }}
                        >
                          Осталось {daysLeft} {lastDigitToWord(daysLeft)}
                        </Table.HeaderCell>
                      ) : (
                        <Table.HeaderCell textAlign="center">
                          <span className="day bad">Нарушение срока</span>
                          <p>
                            {" "}
                            на {daysLeft * -1} {lastDigitToWord(daysLeft)}
                          </p>
                        </Table.HeaderCell>
                      )}
                    </>
                  )}
                </Table.Row>
              </Table.Header>
            </Table>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === id}>
            <Table
              celled
              inverted
              selectable
              style={{ cursor: "pointer" }}
              color="black"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Этап</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Старт</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: 5 }}>
                    План дней
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: 5 }}>
                    +/-
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: 5 }}>
                    Факт дней
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: 10 }}>
                    Факт
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">План</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {moment(beginDate).isValid() ? (
                  <Table.Row className="pay">
                    <Table.Cell textAlign="left">Дата оплаты счёта</Table.Cell>
                    <Table.Cell textAlign="center" colSpan="6">
                      {beginDate}
                    </Table.Cell>
                  </Table.Row>
                ) : null}

{/* ОПЛАТА ИНВОЙСА */}

                {invoiceStartPayDate !== "Invalid date" ? (
                  <Table.Row>
                    <Table.Cell>Оплата инвойса</Table.Cell>
                    <Table.Cell textAlign="center">
                      {invoiceStartPayDate}
                    </Table.Cell>

                    <Table.Cell textAlign="center">{inviocePayDays}</Table.Cell>

                    {Number.isNaN(Number(invoicePayFactDays)) ? (<Table.Cell textAlign="center">-</Table.Cell>) : (<Table.Cell textAlign="center" style={{ width: 5 }}
                    className={invoicePayDiffDays <= 0 ? "day good" : "day bad"}>
                      {invoicePayDiffDays}
                    </Table.Cell>)}

                    {Number.isNaN(Number(invoicePayFactDays)) ? (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        className={
                          invoicePayFactDays <= inviocePayDays ? "day good" : "day bad"
                        }
                      >
                        {invoicePayFactDays}
                      </Table.Cell>
                    )}

                    
                    {invoicePayFactDate !== "Invalid date" ? (
                      <Table.Cell
                        textAlign="center"
                        className={
                          invoicePayFactDays <= inviocePayDays ? "day good" : "day bad"
                        }
                      >
                        {invoicePayFactDate}
                      </Table.Cell>
                    ) : (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    )}
                    <Table.Cell textAlign="center">
                      {invoicePayPlanDate}
                    </Table.Cell>
                  </Table.Row>
                ) : null}

{/* ПРОИЗВОДСТВО */}

                {productStartDate !== "Invalid date" ? (
                  <Table.Row>
                    <Table.Cell>Производство</Table.Cell>
                    <Table.Cell textAlign="center">
                      {productStartDate}
                    </Table.Cell>

                    <Table.Cell textAlign="center">{productionDays}</Table.Cell>

                  
                      {productionFactDate !== "Invalid date" ? (
                         <Table.Cell textAlign="center" style={{ width: 5 }}
                         className={productionDiffDays <= 0 ? "day good" : "day bad"}
                         >{productionDiffDays}</Table.Cell>
                      ) : (
                        <Table.Cell textAlign="center">-</Table.Cell>
                      )}
                    

                    {Number.isNaN(Number(productionFactDays)) ? (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        className={productionFactDays < Number(productionDays) ? "day good" : "day bad"}
                      >
                        {productionFactDays}
                      </Table.Cell>
                    )}
                    
                    {productionFactDate !== "Invalid date" ? (
                      <Table.Cell
                        textAlign="center"
                        className={productionFactDays < Number(productionDays) ? "day good" : "day bad"}
                      >
                        {productionFactDate}
                      </Table.Cell>
                    ) : (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    )}
                    <Table.Cell textAlign="center">
                      {productionPlanDate}
                    </Table.Cell>
                  </Table.Row>
                ) : null}

{/* ВНУТРЕННЯЯ ДОСТАВКА */}

                {intDeliveryStartDate !== "Invalid date" ? (
                  <Table.Row>
                    <Table.Cell>Внутренняя доставка</Table.Cell>
                    <Table.Cell textAlign="center">
                      {intDeliveryStartDate}
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      {domesticShiping}
                    </Table.Cell>

                    {Number.isNaN(Number(intDeliveryFactDays)) ? (
                    <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                    <Table.Cell textAlign="center" style={{ width: 5 }}
                    className={domesticShipDiffDays <= 0 ? "day good" : "day bad"}
                    >{domesticShipDiffDays}</Table.Cell>)}

                    {Number.isNaN(Number(intDeliveryFactDays)) ? (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        className={
                          intDeliveryFactDays < Number(domesticShiping) ? "day good" : "day bad"
                        }
                      >
                        {intDeliveryFactDays}
                      </Table.Cell>
                    )}



                    {intDeliveryFactDate !== "Invalid date" ? (
                      <Table.Cell
                        textAlign="center"
                        className={
                          intDeliveryFactDays < Number(domesticShiping) ? "day good" : "day bad"
                        }
                      >
                        {intDeliveryFactDate}
                      </Table.Cell>
                    ) : (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    )}
                    <Table.Cell textAlign="center">
                      {intDeliveryPlanDate}
                    </Table.Cell>
                  </Table.Row>
                ) : null}

{/* СКЛАД КОНСОЛИДАЦИИ */}

                {warehouseStartDate !== "Invalid date" ? (
                  <Table.Row>
                    <Table.Cell>Консолидация</Table.Cell>
                    <Table.Cell textAlign="center">
                      {warehouseStartDate}
                    </Table.Cell>

                    <Table.Cell textAlign="center">{warehouse}</Table.Cell>

                    <Table.Cell textAlign="center" style={{ width: 5 }}
                    className={warehouseFactDays < warehouse ? "day good" : "day bad"}
                    >{warehouseDiffDays}</Table.Cell>


                    {Number.isNaN(Number(warehouseFactDays)) ? (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        className={
                          warehouseFactDays < warehouse ? "day good" : "day bad"
                        }
                      >
                        {warehouseFactDays}
                      </Table.Cell>
                    )}


                    {customStartDate !== "Invalid date" ? (
                      <Table.Cell
                        textAlign="center"
                        className={warehouseFactDays < warehouse ? "day good" : "day bad"}
                      >
                        {customStartDate}
                      </Table.Cell>
                    ) : (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    )}
                    <Table.Cell textAlign="center">{warehousePlan}</Table.Cell>
                  </Table.Row>
                ) : null}

{/* ТАМОЖНЯ  */}

                {customStartDate !== "Invalid date" ? (
                  <Table.Row>
                    <Table.Cell>Таможня</Table.Cell>
                    <Table.Cell textAlign="center">
                      {customStartDate}
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      {customClearance}
                    </Table.Cell>

                    {Number.isNaN(Number(customFactDays)) ? (<Table.Cell>-</Table.Cell>) : (<Table.Cell textAlign="center" style={{ width: 5 }}
                    className={customDiffDays <= 0 ? "day good" : "day bad"}
                    >{customDiffDays}</Table.Cell>)}

                    {Number.isNaN(Number(customFactDays)) ? (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        className={customFactDays < Number(customClearance) ? "day good" : "day bad"}
                      >
                        {customFactDays}
                      </Table.Cell>
                    )}
                  
                    {carShipStartDate !== "Invalid date" ? (
                      <Table.Cell
                        textAlign="center"
                        className={customFactDays < customClearance ? "day good" : "day bad"}
                      >
                        {carShipStartDate}
                      </Table.Cell>
                    ) : (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    )}
                    <Table.Cell textAlign="center">{customPlan}</Table.Cell>
                  </Table.Row>
                ) : null}

    {/* ДОСТАВКА АВТО */}

                {carShipStartDate !== "Invalid date" ? (
                  <Table.Row>
                    <Table.Cell>Доставка {"Авиа/Авто"}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {carShipStartDate}
                    </Table.Cell>

                    <Table.Cell textAlign="center">{carShipment}</Table.Cell>

                {Number.isNaN(Number(carShipFactDays)) ? (<Table.Cell textAlign="center">-</Table.Cell>) : (<Table.Cell textAlign="center" style={{ width: 5 }}
                className={carShipDiffDays <= 0 ? "day good" : "day bad"}
                >{carShipDiffDays}</Table.Cell>)}


                    {Number.isNaN(Number(carShipFactDays)) ? (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        className={carShipFactDays < carShipment ? "day good" : "day bad"}>
                        {carShipFactDays}
                      </Table.Cell>
                    )}

                    {carShipFactDate !== "Invalid date" ? (
                      <Table.Cell
                        textAlign="center"
                        className={carShipFactDays < carShipment ? "day good" : "day bad"}
                      >
                        {carShipFactDate}
                      </Table.Cell>
                    ) : (
                      <Table.Cell textAlign="center">-</Table.Cell>
                    )}
                    <Table.Cell textAlign="center">
                      {carShipPlanDate}
                    </Table.Cell>
                  </Table.Row>
                ) : null}

{/* СКЛАД МСК */}

                <Table.Row
                  // className={mskFactDays < Number(totalDays) ? "unload good" : "unload bad"}
                >
                  <Table.Cell>Склад МСК</Table.Cell>
                  <Table.Cell textAlign="center">{beginDate}</Table.Cell>

                  <Table.Cell textAlign="center">{totalDaysPlan}</Table.Cell>

                  {Number.isNaN(Number(mskFactDays)) ? (
                  <Table.Cell textAlign="center">-</Table.Cell>
                  ) : (
                  <Table.Cell textAlign="center" style={{ width: 5 }}>{totalDiffDays}</Table.Cell>
                  )}

                  {Number.isNaN(Number(mskFactDays)) ? (
                    <Table.Cell textAlign="center">-</Table.Cell>
                  ) : (
                    <Table.Cell textAlign="center">{mskFactDays}</Table.Cell>
                  )}

                  {mskFactDate !== "Invalid date" ? (
                    <Table.Cell textAlign="center">{mskFactDate}</Table.Cell>
                  ) : (
                    <Table.Cell textAlign="center">-</Table.Cell>
                  )}

                  <Table.Cell textAlign="center">{deadLinePlan}</Table.Cell>
                </Table.Row>

                <Table.Row className="total">
                  <Table.Cell>Отгружено</Table.Cell>

                  {unloadMsk !== "Invalid date" ? (
                    <Table.Cell textAlign="center" colSpan="7">
                      {unloadMsk}
                    </Table.Cell>
                  ) : (
                    <Table.Cell textAlign="center" colSpan="7">
                      -
                    </Table.Cell>
                  )}
                </Table.Row>
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}
