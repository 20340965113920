const getCompany = async (id) => {
    const params = {
      id: id
      };
  
const callBack = (resolve, reject) => (result) => {
      if (result.error()) {
        console.error(result.error());
      } else {
        const data = result.data();
       
       resolve(data);
      }
    }
  
  
    return new Promise((resolve, reject) => {
      BX24.callMethod(
        "crm.company.get",
        params,
        callBack(resolve, reject)
      );
    });

}
  
  export default getCompany;