
const defaultStages = [
  10210275,
  13591469,
  13591463,
  13591475,
  12804945,
  13591471,
  13591472,
  13591473,
  13591474,
  13591464,
  13591467,
  13591470,
  13591466,
  12804948,
  12907605,
  13591458
];

const updateDeals = async (stages = defaultStages) => {
  const params = {
    order: {},
    filter: {
      CATEGORY_ID: 0,
      STAGE_ID: stages
    },
    select: [
      "ID",
      "TITLE",
      "STAGE_ID",
      "COMPANY_ID",
      "BEGINDATE",
      "UF_CRM_1610372487549",
      "UF_CRM_1610374410794",
      "UF_CRM_1610823669494",
      "UF_CRM_1610824236381",
      "UF_CRM_1610824324890",
      "UF_CRM_1610824336128",
      "UF_CRM_1610824356828",
      "UF_CRM_1610824381870",
      "UF_CRM_1610824400804",
      "UF_CRM_1610824493995",
      "UF_CRM_1610824507855",
      "UF_CRM_1610825823454",
      "UF_CRM_1622929066006",
      "UF_CRM_1629215337928"
    ]
  };

  const callBack = (resolve, reject) => (result) => {
    if (result.error()) console.error(result.error());
    else {
      const data = result.data();
      // console.log(data); 
      resolve(data);
    }
  }


  return new Promise((resolve, reject) => {
    BX24.callMethod(
      "crm.deal.list",
      params,
      callBack(resolve, reject)
    );
  });

  
}

export default updateDeals;