import React, { useEffect, useState } from 'react'
import moment from 'moment';
import updateDeals from '../components/services/getDeals';
import getDealFields from '../components/services/getDealFields';
import getCompany from '../components/services/getCompany';
import getSortStageKey from '../components/services/getSortStageKey'
import Deals from '../components/Deals/Deals';
import { Button, Menu, Sticky } from 'semantic-ui-react';

function Main() {

    const [deals, setDeals] = useState([]);
    const [ activeItem, setActiveItem ] = useState({name: 'Все'});

    handleItemClick = (e, { name, stage }) => setActiveItem({name: name, stage: stage})

    useEffect(() => {
        getDealFields();
        updateData(activeItem.stage);
    },[activeItem])

    const updateData = (stages) => {
        const deals = [];
    
        updateDeals(stages).then((data) => {
          data.forEach((item) => {
            const { key } = getSortStageKey(item.STAGE_ID);

            getCompany(item.COMPANY_ID).then((data) => {
              const compName = data.TITLE;
              item.COMPANY = compName;
              item.KEY = key;
              deals.unshift(item);
              setDeals([...deals]);
            });
          });
        });
      }

      const format = 'DD.MM.YYYY';
   

    const getTotalDays = (totalDaysField) => {
      switch (totalDaysField) {
        case '199':
         return  totalDays = 56;

        case '197':
          return  totalDays = 42;
   

        case '201':
          return  totalDays = 28;
      }
    }
  
    console.log(activeItem.name)

  return (
    <div style={{display: 'flex', flexDirection:'column', background: '#353131', opacity: 50, padding: 5, borderRadius: 10}}>
      <Sticky>
      <div style={{display: 'flex', justifyContent: 'space-around', padding: 5}}>
        <Menu color='blue' inverted>
        <Menu.Item
          name='Все'
          active={activeItem.name === 'Все'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='Оплатили счёт'
          stage={['10210275']}
          active={activeItem.name === 'Оплатили счёт'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='Оплата инвойса'
          active={activeItem.name === 'Оплата инвойса'}
          stage={['13591463']}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Производство'
          stage={['12804945','13591471','13591472', '13591473', '13591474']}
          active={activeItem.name === 'Производство'}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Внутренняя доставка'
          stage={['13591464']}
          active={activeItem.name === 'Внутренняя доставка'}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Консолидация'
          stage={['13591467']}
          active={activeItem.name === 'Консолидация'}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Таможня'
          stage={['13591470']}
          active={activeItem.name === 'Таможня'}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Логистика'
          stage={['13591466', '12804948']}
          active={activeItem.name === 'Логистика'}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Склад'
          stage={['12907605']}
          active={activeItem.name === 'Склад'}
          onClick={handleItemClick}
        />
          <Menu.Item
          name='Отгружено'
          stage={['13591458']}
          active={activeItem.name === 'Отгружено'}
          onClick={handleItemClick}
        />
      </Menu>
      </div>
      </Sticky>
        {deals //.filter((deal) => deal.STAGE_ID === '13591463')
          .sort((a, b) => a.KEY - b.KEY)
          .map((deal, index) => {

            const startDate = moment(Date.parse(deal.UF_CRM_1610372487549));
            const startDateFormated = startDate.format(format);

            const totalDays = getTotalDays(deal.UF_CRM_1610825823454)

            const deadLine = moment(startDate).add(totalDays, 'day');
            const deadLineFormated = deadLine.format(format);

            let today = moment();

            const daysLeft = deadLine.diff(today, 'days').toString();
            const daysPass = today.diff(startDate, 'days').toString();

            let color = 'grey';
            let stage = null;
            let icon = '';

            const payStageTime = 3, //Время на запрос инвойса
              invoicePrePayDays = 3,
              inviocePayDays = 7,
              domesticShiping = 5,
              customClearance = 10,
              airShipment = 3,
              carShipment = 14,
              warehouse = 3;


              const productionDays = deal.UF_CRM_1610374410794,
              domesticShipDate = moment(deal.UF_CRM_1610824356828).format(format);
            // Pay
            // a = moment(startDate);
            const invoicePayStart = moment(deal.UF_CRM_1610823669494);
            const PayFact = invoicePayStart.diff(startDate, 'days').toString();

            // Invoice
            // moment(deal.UF_CRM_1610823669494); // Старт оплаты инвойса
            // moment(deal.UF_CRM_1610824236381); // Дата оплаченого инвойса
            const invoiceStartPayDate = invoicePayStart.format(format);

            // const invoicePayPlan = moment(deal.UF_CRM_1610823669494).add(inviocePayDays, 'days');
            const invoicePayPlanDate = moment(deal.UF_CRM_1610823669494).add(inviocePayDays, 'days').format(format).toString();
            const invoicePayFactDate = moment(deal.UF_CRM_1610824236381).format(format);
            const invoicePayFactDays = moment(deal.UF_CRM_1610824236381).diff(moment(deal.UF_CRM_1610823669494), 'days').toString();

            const invoicePrePayProgressDays = today.diff(moment(deal.UF_CRM_1610372487549), 'days');
            // const invoicePayProgress = invoicePayPlan.diff(inviocePayDays, 'days');
            const invoicePayProgressDays = today.diff(moment(deal.UF_CRM_1610823669494), 'days');

            //Production
            const productionStart = moment(deal.UF_CRM_1610824324890); // Дата начала производства
            const productionEndDate = moment(deal.UF_CRM_1610824336128); // Дата окончания производства
            const productStartDate = productionStart.format(format).toString();

            const productionPlan = productionStart.add(productionDays, 'days');
            const productionPlanDate = productionPlan.format(format).toString();
            const productionFactDate = productionEndDate.format(format);
            const productionFactDays = moment(deal.UF_CRM_1610824336128).diff(moment(deal.UF_CRM_1610824324890), 'days').toString();

            const productionProgressDays = today.diff(moment(deal.UF_CRM_1610824324890), 'days');
            
            // const productionProgressDays = invoicePayPlan.subtract(productionProgress, 'days').format('D');

            // console.log(productionProgressDays)
            
        
            //Internal delivery
            const intDeliveryStart = moment(deal.UF_CRM_1610824356828); // Старт внутренней доставки
            const warehouseStart = moment(deal.UF_CRM_1610824381870); // Поступление на склад консолидации
            const intDeliveryStartDate = intDeliveryStart.format(format);

            const intDeliveryPlanDate = intDeliveryStart.add(domesticShiping, 'days').format(format).toString();
            const intDeliveryFactDate = warehouseStart.format(format);
            const intDeliveryFactDays = moment(deal.UF_CRM_1610824381870).diff(moment(deal.UF_CRM_1610824356828), 'days').toString();

            const intDeliveryProgressDays = today.diff(moment(deal.UF_CRM_1610824356828), 'days');


            //Warehouse
            // const warehouseStart = moment(deal.UF_CRM_1610824381870); // Дата поступления на склад консолидации
            const customStart = moment(deal.UF_CRM_1610824400804); // Дата поступления на таможню
            const warehouseStartDate = warehouseStart.format(format);

            const warehousePlan = warehouseStart.add(3, 'days').format(format).toString();
            const warehouseFactDate = customStart.format(format);
            const warehouseFactDays = moment(deal.UF_CRM_1610824400804).diff(moment(deal.UF_CRM_1610824381870), 'days').toString();


            //Custom clearence
            // a = moment(deal.UF_CRM_1610824400804); // Дата поступления на таможню
            const autoStart = moment(deal.UF_CRM_1610824493995);
            const customStartDate = customStart.format(format);

            const customPlan = customStart.add(customClearance, 'days').format(format).toString();
            const customFactDate = autoStart.format(format);
            const customFactDays = moment(deal.UF_CRM_1610824493995).diff(moment(deal.UF_CRM_1610824400804), 'days').toString();

            const customClearanceProgressDays = today.diff(moment(deal.UF_CRM_1610824400804), 'days')

            //Auto Delivery

            // a = moment(deal.UF_CRM_1610824493995);
            const mskStart = moment(deal.UF_CRM_1622929066006);

            const carShipStartDate = autoStart.format(format);

            const carPlan = autoStart.add(carShipment, 'days');
            const carShipPlanDate = autoStart.add(carShipment, 'days').format(format).toString();
            const carShipFactDate = mskStart.format(format);
            const carShipFactDays = moment(deal.UF_CRM_1622929066006).diff(moment(deal.UF_CRM_1610824493995), 'days').toString();

            
            const carProgressDays = today.diff(moment(deal.UF_CRM_1610824493995), 'days')

            //Moscow warehouse
            a = moment(deadLine);
            // const mskFactDays = mskStart.diff(a, 'days').toString();
            const mskFactDate = mskStart.format(format);

            //Moscow Unload
            const unloadMsk = moment(deal.UF_CRM_1629215337928).format(format);

            let currentStageTotal = null;
            let currentStageDaysLeft = null;
            let startStage = null;
            let endOfStage = null;
            let stageProgress = null;
            let stageTotal = null;


            switch (deal.STAGE_ID) {
              case '10210275':
                color = 'green';
                stage = 'Оплачен счёт';
                icon = 'file outline';

                // stageProgress = null;
                // stageTotal = payStageTime;
                
                break;

              case '13591469':
                color = 'red';
                stage = 'Инвойс к оплате';
                icon = 'file outline';

                stageProgress = invoicePrePayProgressDays;
                stageTotal = invoicePrePayDays;

                break;

              case '13591463':
                color = 'orange';
                stage = 'Оплата инвойса';
                icon = 'file outline';
                // startStage = invoicePayStart.format(format).toString();
                // endOfStage = invoicePayStart
                //   .add(inviocePayDays, 'days')
                //   .format(format)
                //   .toString();

                  stageProgress = invoicePayProgressDays;
                  stageTotal = inviocePayDays;

                break;

              case '13591475':
                color = 'olive';
                stage = 'Инвойс оплачен';
                icon = 'file outline';
                break;

              case '12804945':
                color = 'violet';
                stage = 'Пр-во Велкро';
                icon = 'file outline';

                stageProgress = productionProgressDays
                stageTotal = productionDays;
                
                break;

              case '13591471':
                color = 'violet';
                stage = 'Пр-во Сетки';
                icon = 'file outline';
               
                stageProgress = productionProgressDays
                stageTotal = productionDays;

                break;

              case '13591472':
                color = 'violet';
                stage = 'Пр-во 3D Спейсер';
                icon = 'file outline';
                
                stageProgress = productionProgressDays
                stageTotal = productionDays;

                break;

              case '13591473':
                color = 'violet';
                stage = 'Пр-во Петельное полотно';
                icon = 'file outline';
                
                stageProgress = productionProgressDays
                stageTotal = productionDays;

                break;

              case '13591474':
                color = 'violet';
                stage = 'Пр-во CORDURA';
                icon = 'file outline';
                
                stageProgress = productionProgressDays
                stageTotal = productionDays;

                break;

              case '13591464':
                color = 'blue';
                stage = 'Внутр. перевозка';
                icon = 'truck';

                stageProgress = intDeliveryProgressDays;
                stageTotal = domesticShiping;
         
                break;

              case '13591467':
                color = 'orange';
                stage = 'Склад консолидации';
                icon = 'truck';
                // startStage = warehouseStart.format(format).toString();
                // endOfStage = moment().format(format).toString();
                break;

              case '13591470':
                color = 'green';
                stage = 'Таможня';
                icon = 'truck';

                stageProgress = customClearanceProgressDays
                stageTotal = customClearance;

                // startStage = customStart.format(format).toString();
                // endOfStage = customStart
                //   .add(customClearance, 'days')
                //   .format(format)
                //   .toString();
                break;

              case '13591466':
                color = 'purple';
                stage = 'В пути Авиа';
                icon = 'truck';
                // startStage = airShipDate.format(format).toString();
                // endOfStage = airShipDate
                //   .add(airShipment, 'days')
                //   .format(format)
                //   .toString();

                
                break;

              case '12804948':
                color = 'purple';
                stage = 'В пути Авто';
                icon = 'truck';
                // startStage = autoStart.format(format).toString();
                // endOfStage = autoStart
                //   .add(carShipment, 'days')
                //   .format(format)
                //   .toString();

                stageProgress = carProgressDays;
                stageTotal = carShipment;
                  // stageProgress = carProgressDays;
                break;

              case '12907605':
                color = 'pink';
                stage = 'Склад МСК';
                icon = 'box';
                break;

              case '13591458':
                color = 'green';
                stage = 'Отгружено';
                icon = 'box';
                break;
            }

            return (
              <>
              <Deals
                key={deal.ID}
                id={deal.ID}
                name={deal.TITLE}
                companyName={deal.COMPANY}
                PayFact={PayFact}
                start={deal.UF_CRM_1610372487549}
                beginDate={startDateFormated}
                totalDays={totalDays}
                deadline={deadLineFormated}
                daysLeft={daysLeft}
                daysPass={daysPass}
                stage={stage}
                stageTotal={stageTotal}
                stageProgress={stageProgress}
                color={color}
                icon={icon}
                startStage={startStage}
                endOfStage={endOfStage}
                currentStageTotal={currentStageTotal}
                currentStageDaysLesft={currentStageDaysLeft}
                dealStageId={deal.STAGE_ID}
                payStageTime={payStageTime}
                invoiceStartPayDate={invoiceStartPayDate}
                invoicePrePayDays={invoicePrePayDays}
                inviocePayDays={inviocePayDays}
                invoicePayPlanDate={invoicePayPlanDate}
                invoicePayFactDate={invoicePayFactDate}
                invoicePayFactDays={invoicePayFactDays}
                productionDays={productionDays}
                productStartDate={productStartDate}
                productionPlanDate={productionPlanDate}
                productionFactDays={productionFactDays}
                productionFactDate={productionFactDate}
                productionDaysProgress={productionProgressDays}
                domesticShiping={domesticShiping}
                intDeliveryStartDate={intDeliveryStartDate}
                intDeliveryPlanDate={intDeliveryPlanDate}
                intDeliveryFactDays={intDeliveryFactDays}
                domesticShipDate={domesticShipDate}
                intDeliveryFactDate={intDeliveryFactDate}
                customClearance={customClearance}
                customFactDays={customFactDays}
                customStartDate={customStartDate}
                customFactDate={customFactDate}
                customPlan={customPlan}
                warehouse={warehouse}
                warehouseStartDate={warehouseStartDate}
                warehouseFactDays={warehouseFactDays}
                warehousePlan={warehousePlan}
                warehouseFactDate={warehouseFactDate}
                airShipment={airShipment}
                carShipment={carShipment}
                carShipFactDays={carShipFactDays}
                carShipStartDate={carShipStartDate}
                carShipPlanDate={carShipPlanDate}
                carShipFactDate={carShipFactDate}
                // mskFactDays={mskFactDays}
                mskFactDate={mskFactDate}
                unloadMsk={unloadMsk}
              />
              </>
            );
          })}
      </div>
    );
}

export default Main;