/* eslint no-use-before-define: 0 */ 


const getDealFields = async () => {
 
    const params = {
     
    }
  
    const callBack = (resolve, reject) => (result) => {
      if (result.error()) console.error(result.error());
      else {
        const data = result.data();
        // console.log(data);
        resolve(data);
      }
    }
  
  
    return new Promise((resolve, reject) => {
      // eslint-disable-line
      BX24.callMethod(
        'crm.deal.userfield.list',
        params,
        callBack(resolve, reject)
      );
    });

}
  
  export default getDealFields;