export const blocks = (productionDaysProgress) => {
  let blocks = [];

  if (productionDaysProgress == productionDaysProgress) {
    if(productionDaysProgress > 0){
      const a = Number(productionDaysProgress);
     for(let i = 0; i < a; i++){
      blocks.push(i);
     } 
    }
  }
  return blocks;
}